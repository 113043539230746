<template>
  <div id="app">
    <div class="mb-3">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
</style>
